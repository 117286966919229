import './Complements10.css';
import {randoms1toN} from "../../components/commons";
import "bootstrap/dist/js/bootstrap.bundle"
import "bootstrap/dist/css/bootstrap.min.css"
import XOperation from "../../components/XOperation";

const levels = Array.from({length: 2}, (_, i) => i)

let generateOperands = (level = 0) => {
    switch (level) {
        case 0:
            return {
                a: 10,
                b: randoms1toN(5, 1),
                proposals: randoms1toN(5, 3)
            }
        case 1:
        default:
            return {
                a: 10,
                b: randoms1toN(10, 1),
                proposals: randoms1toN(10, 3)
            }
    }
}

function Complements10({size}) {

    return (
        <XOperation storageKey={"complement10.level"}
                    title="Les Compléments de 10"
                    op={(a, b) => a - b}
                    levels={levels}
                    generator={generateOperands}
                    size={size}
                    titleFormater={(answer => `compl. de ${answer.operand2}`)}
        />
    );

}

export default Complements10;