export function shuffle(array) {
    let currentIndex = array.length;
    while (currentIndex !== 0) {
        let randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
    return array
}

export function initEmptyArray(size) {
    return Array.apply(null, Array(size)).map(() => {
        return undefined;
    })
}

export function loadLevel(storageKey) {
    let level = 1
    if (localStorage.getItem(storageKey) === null) {
        localStorage.setItem(storageKey, level.toString())
    } else {
        level = Number(localStorage.getItem(storageKey))
    }
    return level
}

export function saveLevel(level, storageKey) {
    localStorage.setItem(storageKey, level)
    return level
}

export function gotoNextLevel(level, levels) {
    let nextLevel = level + 1
    if (levels.find(l => l === nextLevel) !== undefined) {
        level = nextLevel
    }
    return level
}

function generateN(n: Number): Number {
    return Math.floor(Math.random() * n) + Number(1)
}

export function randoms1toN(n = 10, size = 1) {
    if (size === 1) {
        return generateN(n)
    }
    let arr = [];
    let i = 0;
    do {
        let x = generateN(n)
        if (!arr.find(el => el === x)) {
            arr.push(x)
            i++;
        }
    } while (i < size)
    return arr;
}
