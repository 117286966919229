import './Doubles.css';
import {randoms1toN} from "../../components/commons";
import "bootstrap/dist/js/bootstrap.bundle"
import "bootstrap/dist/css/bootstrap.min.css"
import XOperation from "../../components/XOperation";

const levels = Array.from({length: 3}, (_, i) => i)

let generateOperands = (level = 0) => {
    switch (level) {
        case 0:
            return {
                a: randoms1toN(5),
                b: 1,
                proposals: randoms1toN(10, 3)
            }
        case 1:
            return {
                a: randoms1toN(10),
                b: 1,
                proposals: randoms1toN(20, 3)
            }
        case 2:
        default:
            return {
                a: randoms1toN(20),
                b: 1,
                proposals: randoms1toN(40, 3)
            }
    }
}

function Doubles({size}) {

    return (
        <XOperation storageKey={"doubles.level"}
                    op={(a, b) => a * 2}
                    title="Les Doubles"
                    levels={levels}
                    generator={generateOperands}
                    size={size}
                    titleFormater={(answer => `${answer.operand1} x 2`)}
        />
    );
}

export default Doubles;