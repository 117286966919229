import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./ToolBar.css";
import {faForward, faStar} from "@fortawesome/free-solid-svg-icons";

function ToolsBar({onNext, reinit = () => {}, countValid, total, disableNext = true}) {

    function star(valid: boolean) {
        return (
            <div className={"col text-center align-content-center"}>
                <i>
                    <FontAwesomeIcon icon={faStar} inverse={valid} size={"lg"}></FontAwesomeIcon>
                </i>
            </div>
        )
    }

    function stars(total, countValid) {
        let stars = []
        for (let i = 0; i <countValid; i++) {
            stars.push(star(true))
        }
        for (let i = countValid; i <total; i++) {
            stars.push(star(false))
        }
        return stars;
    }

    return (
        <div className={"row"}>
            <div className={"col-3 align-content-center"}>
                <div className={"row h-100"}>
                {stars(total, countValid)}
                </div>
            </div>
            <div className={"col"}></div>
            <div className={"col-2 align-content-center"}>
            <button disabled={disableNext} type="button" className={"btn btn-success btn-next w-100 h-100"}
                        onClick={onNext}>
                    <i><FontAwesomeIcon icon={faForward}/> Suivant</i>
                </button>
            </div>
        </div>
    )
}

export default ToolsBar;
