import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Menu from "./menu/Menu";

function App() {
  return (
      <div className="App">
          <header className="App-header">
              <div className="container text-center">
                  <div className="row">
                      <Menu />
                  </div>
                  <div className={"row justify-content-center text-info"}>
                      <div className={"card"}>
                          <div className={"card-body"}>
                              Apprendre le calcul avec <i><b>Kalky</b></i>
                          </div>
                      </div>
                  </div>
              </div>
          </header>
      </div>
  );
}

export default App;
