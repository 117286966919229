import './Addition.css';
import "bootstrap/dist/js/bootstrap.bundle"
import "bootstrap/dist/css/bootstrap.min.css"
import {randoms1toN} from "../../components/commons";
import XOperation from "../../components/XOperation";

const levels = Array.from({length: 9}, (_, i) => i)

let generator = (level = 0) => {
    switch (level) {
        case 0:
            return {
                a: randoms1toN(5, 1),
                b: randoms1toN(5, 1),
                proposals: randoms1toN(5, 3)
            }
        case 1:
            return {
                a: 10,
                b: randoms1toN(5),
                proposals: randoms1toN(20, 3)
            }
        case 2:
            return {
                a: randoms1toN(5) + 5,
                b: randoms1toN(5),
                proposals: randoms1toN(20, 3)
            }
        case 3:
            return {
                a: randoms1toN(10),
                b: randoms1toN(10),
                proposals: randoms1toN(20, 3)
            }
        case 4:
            return {
                a: randoms1toN(10) * 10,
                b: randoms1toN(10),
                proposals: randoms1toN(100, 3)
            }
        case 5:
            return {
                a: randoms1toN(10) * 10,
                b: randoms1toN(10) * 10,
                proposals: Array.apply(null, randoms1toN(10, 3))
                    .map(value => value * 10)
            }
        case 6:
            return {
                a: randoms1toN(10) * 10,
                b: randoms1toN(10) + 10,
                proposals: randoms1toN(100, 3)
            }
        case 7:
            return {
                a: randoms1toN(10) * 10,
                b: randoms1toN(100),
                proposals: randoms1toN(200, 3)
            }
        case 8:
        default:
            return {
                a: randoms1toN(100),
                b: randoms1toN(100),
                proposals: randoms1toN(200, 3)
            }
    }
}

function Addition({size}) {
    return (
        <XOperation storageKey={"addition.level"}
                    title="Les Additions"
                    op={(a, b) => a + b}
                    levels={levels}
                    generator={generator}
                    size={size}
                    titleFormater={(answer => `${answer.operand1} + ${answer.operand2}`)}
                    />
    );
}

export default Addition;
