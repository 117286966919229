import 'bootstrap/dist/css/bootstrap.min.css'
import './Section.css'
import {Link} from "react-router-dom";

function Section({ text, icon = null, path, title }) {
    return (
        <div className={"col-4 p-1"}>
            <Link to={path} style={{ textDecoration: 'none'}}>
            <div className="card menu-item">
                <div className={"card-header"}>
                    <b>{title !== null ? title : text}</b>
                </div>
                <div className="card-body">
                    <i>{text}</i>
                </div>
            </div>
            </Link>
        </div>
    );
}

export default Section;
