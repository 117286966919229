import './Divisions.css';
import {randoms1toN} from "../../components/commons";
import "bootstrap/dist/js/bootstrap.bundle"
import "bootstrap/dist/css/bootstrap.min.css"
import XOperation from "../../components/XOperation";

const levels = Array.from({length: 3}, (_, i) => i)

let generateOperands = (level = 0) => {
    let a
    let b
    switch (level) {
        case 0:
            a = randoms1toN(5)
            b = randoms1toN(5)
            return {
                a: a * b,
                b: b,
                proposals: randoms1toN(10, 3)
            }
        case 1:
            a = randoms1toN(10)
            b = randoms1toN(5)
            return {
                a: a * b,
                b: b,
                proposals: randoms1toN(10, 3)
            }
        case 2:
        default:
            a = randoms1toN(10)
            b = randoms1toN(9) + 1
            return {
                a: a * b,
                b: b,
                proposals: randoms1toN(10, 3)
            }
    }
}

function Divisions({size}) {

    return (
        <XOperation storageKey={"divisions.level"}
                    op={(a, b) => a / b}
                    title="Les Divisions"
                    levels={levels}
                    generator={generateOperands}
                    size={size}
                    titleFormater={(answer => `${answer.operand1} / ${answer.operand2}`)}
        />
    );

}

export default Divisions;