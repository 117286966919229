import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Addition from "./operations/addition/Addition";
import App from "./App";
import Soustraction from "./operations/soustraction/Soustraction";
import Complements10 from "./operations/complement-10/Complements10";
import Doubles from "./operations/double/Doubles";
import Multiplications from "./operations/multiplication/Multiplications";
import Divisions from "./operations/division/Divisions";
import Moities from "./operations/moitie/Moities";

const router = createBrowserRouter([
    {
        path: "/additions",
        element: <Addition size={6} />
    },
    {
        path: "/soustractions",
        element: <Soustraction size={6} />
    },
    {
        path: "/complements-10",
        element: <Complements10 size={6} />
    },
    {
        path: "/doubles",
        element: <Doubles size={6} />
    },
    {
        path: "/moities",
        element: <Moities size={6} />
    },
    {
        path: "/multiplications",
        element: <Multiplications size={6} />
    },
    {
        path: "/divisions",
        element: <Divisions size={6} />
    },
    {
        path: "/",
        element: <App />
    }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
