import {useState} from "react";
import Operation from "./Operation";
import ToolBar from "../toolbar/ToolBar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons/faXmark";
import "bootstrap/dist/js/bootstrap.bundle"
import "bootstrap/dist/css/bootstrap.min.css"
import {gotoNextLevel, initEmptyArray, loadLevel, saveLevel, shuffle} from "./commons";
import Header from "../header/Header";

function initAnswers(size, level, generator: Function, op: Function) {
    let answers = []
    for (let i = 0; i < size;) {

        let trials = 0
        let operands
        do {
            operands = generator(level);
            trials++
        } while (
            answers.find(v => v.operand1 === operands.a && v.operand2 === operands.b) && trials <= 10);

        let operand1 = operands.a;
        let operand2 = operands.b;
        let correct = op(operand1, operand2)

        answers.push({
            proposals: shuffle([correct, ...operands.proposals.slice(1)]),
            operand1: operand1,
            operand2: operand2,
            index: i,
            proposal: -1,
            correct: correct,
            isValid: undefined,
            miss: 0
        });
        i++;
    }
    return answers
}

function Alert({size, countValid, hideAlert}) {
    return (
        <div className={"row"}>
            <div className="col text-center alert alert-primary" role="alert">
                Il te manque encore {size - countValid} réponses à trouver !
                <FontAwesomeIcon icon={faXmark} className={"float-end"} onClick={hideAlert}/>
            </div>
        </div>
    )
}

function XOperation({size, generator, op, titleFormater, levels, storageKey, title = '', levelChanged = () => {}}) {

    const [level, setLevel] = useState(loadLevel(storageKey))
    const [answers, setAnswers] = useState(initAnswers(size, level, generator, op))
    const [valids, setValids] = useState(initEmptyArray(size))
    const [countValid, setCountValid] = useState(0)
    const [disableNext, setDisableNext] = useState(true)

    let chosen = (value, index) => {
        let answer = answers[index]
        answer.isValid = Number(answer.correct) === Number(value)
        if (! answer.isValid) {
            answer.miss = answer.miss + 1
        }

        setAnswers([
            ...answers.slice(0, index),
                answer,
            ...answers.slice(index+1)
            ])

        setValids([
            ...valids.slice(0, index),
                answer.isValid,
            ...valids.slice(index+1)
        ])

        setCountValid(answers.filter(value => value.isValid && value.miss === 0).length)
        setDisableNext(answers.filter(value => value.isValid).length !== size)
    };

    let next = () => {
        if (Number(countValid) === Number(size) && answers.filter(value => value.miss > 0).length === 0) {
            //setShowAlert(true)
            //} else {
            let l = gotoNextLevel(level, levels, storageKey)
            changeLevel(l)
        } else {
            window.location.reload()
        }
    }

    let changeLevel = (l) => {
        setLevel(l)
        saveLevel(l, storageKey)
        levelChanged(l)
        window.location.reload()
    }

    let gotoLevel = (event) => {
        let l = Number(event.target.id)
        changeLevel(l)
    }

    let components = [];
    let cols = 3;
    let rows = size / cols;
    for (let i = 0; i < rows; i++) {
        let j=i*cols
        components.push(
            <div className={"row"}>
                {
                    answers.slice(j, j+3).map(answer =>
                        <div className={"col p-2"}>
                            <Operation key={answer.index}
                                       title={titleFormater(answer)}
                                       proposals={answer.proposals}
                                       onClick={(value) => chosen(value, answer.index)}
                                       isValid={valids[answer.index]}
                            />
                        </div>
                    )
                }
            </div>
            )
    }

    return (
        <div className={"container-fluid"}>
            <Header level={level} levels={levels} gotoLevel={gotoLevel} title={title}/>
            {
                components
            }
            <ToolBar onNext={next}
                     disableNext={disableNext}
                     countValid={countValid}
                     total={size}
            />
        </div>
    )
}

export default XOperation;
