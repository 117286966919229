import './Multiplications.css';
import {randoms1toN} from "../../components/commons";
import "bootstrap/dist/js/bootstrap.bundle"
import "bootstrap/dist/css/bootstrap.min.css"
import XOperation from "../../components/XOperation";

const levels = Array.from({length: 3}, (_, i) => i)

let generateOperands = (level = 0) => {
    switch (level) {
        case 0:
            return {
                a: randoms1toN(5),
                b: randoms1toN(5),
                proposals: randoms1toN(30, 3)
            }
        case 1:
            return {
                a: randoms1toN(4) + 1,
                b: randoms1toN(9) + 1,
                proposals: randoms1toN(50, 3)
            }
        case 2:
        default:
            return {
                a: randoms1toN(9) + 1,
                b: randoms1toN(9) + 1,
                proposals: randoms1toN(100, 3)
            }
    }
}

function Multiplications({size}) {

    return (
        <XOperation storageKey={"multiplications.level"}
                    title="Les Multiplications"
                    op={(a, b) => a * b}
                    levels={levels}
                    generator={generateOperands}
                    size={size}
                    titleFormater={(answer => `${answer.operand1} x ${answer.operand2}`)}
        />
    );
}

export default Multiplications;