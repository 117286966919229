import './Operation.css';
import Proposition from "./Proposition";
import {useState} from "react";

const classChosen = "w-100 btn btn-success btn-block"
const classUnchosen = "w-100 btn btn-outline-primary btn-block"

function Operation({title, proposals, onClick, isValid}) {
    const [className0, setClassName0] = useState(classUnchosen)
    const [className1, setClassName1] = useState(classUnchosen)
    const [className2, setClassName2] = useState(classUnchosen)
    let toggleClasses = (index) => {
        if (index === 0) {
            setClassName0(classChosen)
            setClassName1(classUnchosen)
            setClassName2(classUnchosen)
        }
        if (index === 1) {
            setClassName0(classUnchosen)
            setClassName1(classChosen)
            setClassName2(classUnchosen)
        }
        if (index === 2) {
            setClassName0(classUnchosen)
            setClassName1(classUnchosen)
            setClassName2(classChosen)
        }
    };

    let clicked0 = () => {
        toggleClasses(0)
        onClick(proposals[0])
    }
    let clicked1 = () => {
        toggleClasses(1)
        onClick(proposals[1])
    }
    let clicked2 = () => {
        toggleClasses(2)
        onClick(proposals[2])
    }

    let className = "card text-center "
    if (isValid === true) {
        className += "valid"
    } else if (isValid === false) {
        className += "invalid"
    } else {
        // do nothing
    }

    return (
        <div className={className}>
            <div className={"card-header"}>
                <h1>{title}</h1>
            </div>
            <div className="card-body">
                <div className={"card-text"}>
                    <div className={"row"}>
                        <div className={"col"}>
                            <Proposition value={proposals[0]} onclick={clicked0} className={className0} />
                        </div>
                        <div className={"col"}>
                            <Proposition value={proposals[1]} onclick={clicked1} className={className1} />
                        </div>
                        <div className={"col"}>
                            <Proposition value={proposals[2]} onclick={clicked2} className={className2} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Operation;
