import Section from "./Section";

interface MenuItem {
    title: string,
    description: string,
    path: string
}

function Menu() {
    let menu: MenuItem[] = [
        {
            title: 'Les additions',
            description: 'révise tes additions et monte en niveau',
            path: '/additions'
        },
        {
            title: 'Les soustractions',
            description: 'les soustractions faciles avec Kalky',
            path: '/soustractions'
        },
        {
            title: 'Les compléments de 10',
            description: 'essentiel pour un calcul mental rapide',
            path: '/complements-10'
        },
        {
            title: 'Les multiplications',
            description: 'A force de répétition et de travail, deviens un champion des X',
            path: '/multiplications'
        },
        {
            title: 'Les doubles',
            description: 'entraine toi à apprendre les doubles et impressione tes amis',
            path: '/doubles'
        },
        {
            title: 'Les moitiés',
            description: 'cela va de paire avec les doubles',
            path: '/moities'
        },
        {
            title: 'Les divisions',
            description: 'avec les divisions, le calcul mental sera un jeu d\'enfant pour toi',
            path: '/divisions'
        }
    ];

    return (
        <div className={"container"}>
            <div className={"row"}>
            {
                menu.map(value =>
                    <Section
                        title={value.title}
                        text={value.description}
                        path={value.path}
                    />
                )
            }
            </div>
        </div>
    )
}

export default Menu;