import './Soustractions.css'
import {randoms1toN} from "../../components/commons";
import XOperation from "../../components/XOperation";

const levels = Array.from({length: 4}, (_, i) => i)

let generateOperands = (level = 0) => {
    switch (level) {
        case 0:
            return {
                a: randoms1toN(5, 1) + 5,
                b: randoms1toN(5, 1),
                proposals: randoms1toN(5, 3)
            }
        case 1:
            return {
                a: randoms1toN(5, 1) + 10,
                b: randoms1toN(10),
                proposals: randoms1toN(10, 3)
            }
        case 2:
            return {
                a: randoms1toN(10, 1) + 10,
                b: randoms1toN(10),
                proposals: randoms1toN(10, 3)
            }
        case 3:
            return {
                a: randoms1toN(10, 1) + 20,
                b: randoms1toN(10),
                proposals: randoms1toN(30, 3)
            }
        case 4:
        default:
            return {
                a: randoms1toN(10) + 30,
                b: randoms1toN(10),
                proposals: randoms1toN(30, 3)
            }
    }
}

function Soustraction({size}) {
    return (
        <XOperation storageKey={"soustraction.level"}
                    title="Les Soustractions"
                    op={(a,b) => a-b}
                    levels={levels}
                    generator={generateOperands}
                    size={size}
                    titleFormater={(answer => `${answer.operand1} - ${answer.operand2}`)}
        />
    );
}

export default Soustraction;
