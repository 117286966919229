import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import './Header.css'

function Header({gotoLevel = () => {}, level, levels, title = 'any'}) {
    return (
        <div className={"row"}>
            <div className={"col-1 align-content-center text-center"}>
                <Link to={"/"}>
                    <button type={"button"} className={"btn btn-info"}>
                        <i><FontAwesomeIcon icon={faHome}/></i>
                    </button>
                </Link>
            </div>
            <div className={"col-9 justify-content-center text-info text-center"}>
                <div className={"card"}>
                    <div className={"card-body"}>
                        <h3 className={"title"}>{title}</h3>
                    </div>
                </div>
            </div>
            <div className={"col-2 align-content-center text-center"}>
                <div className="dropdown">
                    <button className="btn btn-outline-info btn-lg dropdown-toggle" type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown" aria-expanded="false">
                        Niveau {level}
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        {
                            levels.map(l =>
                                <li key={l}><a className="dropdown-item" key={l} id={l} href="#"
                                               onClick={gotoLevel}>Niveau {l}</a></li>
                            )
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Header;